.card[data-v-2def78ff]  .card-header .heading-elements {
  position: static;
}[dir] .card[data-v-2def78ff]  .card-header .heading-elements {
  cursor: inherit;
}
.card[data-v-2def78ff]  .card-header .heading-elements .list-inline {
  display: block;
}
[dir] .card[data-v-2def78ff]  .card-header .heading-elements .list-inline li a {
  padding: 0;
}
[dir=ltr] .card[data-v-2def78ff]  .card-header .heading-elements .list-inline li:not(:last-child) {
  margin-right: 1rem;
}
[dir=rtl] .card[data-v-2def78ff]  .card-header .heading-elements .list-inline li:not(:last-child) {
  margin-left: 1rem;
}
[dir] .dark-layout .b-overlay-wrap[data-v-2def78ff]  .b-overlay .bg-white {
  background-color: #161d31 !important;
}